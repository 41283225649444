@import 'styles/variables';

.navbar {
    padding: 0 !important;
    position: absolute;
    height: 60px;
    background-color: $cetacean-blue;
    border-bottom: $regular-border;
}

.single-item-container {
    background-color: transparent;
    box-shadow: none !important;
    border-bottom: 1px solid $menu-white;
    max-height: 50px;
    height: 100%;
    padding: 0 24px;
    color: $white;
    letter-spacing: 0.51px;
    font-size: $small;
    opacity: 0.9;

    &:hover {
        background: $menu-purple-hover;
    }
}

.single-item {
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
}

.navbar-transparent {
    background: transparent;
}

#navigation-bar {
    .container-fluid {
        padding: 0 55px 0 30px;

        @media (width <= $extrascreen) {
            padding: 0 40px 0 18px;
        }
    }

    .navbar-logo {
        margin-right: 48px !important;
        height: 40px;
        border-right: 1px solid $color-grey-100;
        border-radius: 0;

        @media (max-width: $phone) {
            margin-right: 16px;
        }

        img {
            height: 15px;
            margin-right: 52px;
        }
    }

    .toggler-icon {
        border: none;
        background: transparent;
        height: 40px;
        width: 26px;
        margin-right: 14px;
        z-index: 90;

        .top,
        .middle,
        .bottom {
            height: 2px;
            width: 20px;
            background: $white;
            margin: 5px;
            border-radius: 4px;
        }

        @media (min-width: $mediumcreen) {
            display: none !important;
        }
    }

    .nav-link {
        color: $white;
        margin-right: 35px;
        font-size: $regular;
        line-height: 24px;
        transition: all .2s ease-in-out;
        cursor: pointer;

        @media (max-width: $desktop) {
            font-size: $small;; 
        }
    }

    .link-none {
        @media (max-width: $mediumcreen) {
            display: none !important; 
        }
    }

    .dropdown-navbar {
        display: flex;
        flex-direction: row;
        align-items: center;

        @media (max-width: $desktop) {
            margin-top: 5px;
        }
    }

    .nav-item-dropdown {
        @media (max-width: $phone) {
            display: none;
        }
    }

    .nav-item {
        @media (min-width: $desktop) {
            &:hover {
                .nav-link {
                    color: $sign-up-blue;
                }

                .purple-hover {
                    filter: invert(54%) sepia(95%) saturate(7491%) hue-rotate(245deg) brightness(94%) contrast(100%);
                }
            }
        }
    }

    .btn-primary {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 104px;
        height: 44px;
        border: none;
        border-radius: 4px;
        padding: 0;
        background: $purple-blue;
    }

    .sign-in-icon {
        --md-icon-size: 20px;
        margin-bottom: 2px;
    }

    .font-sign {
        font-size: $medium;
        line-height: 24px;
        color: $white;
        margin-right: 3px;
    }

    .arrow-container-down {
        position: relative;
        background-color: transparent;
        transition: all .2s ease-in-out;

        .stick-1 {
            position: absolute;
            width: 2px;
            height: 10px;
            background-color: white;
            border-radius: 10px;
        }

        .stick-2 {
            position: absolute;
            width: 2px;
            height: 9px;
            background-color: white;
            border-radius: 10px;
        }
        .stick-1 {
            transform: rotate(135deg);
        }
        .stick-2 {
            transform: rotate(45deg);
        }
    }

    .arrow-container-up {
        position: relative;
        background-color: transparent;
        transition: all .2s ease-in-out;
        visibility: hidden;
        opacity: 0;

        .stick-1, .stick-2 {
            position: absolute;
            width: 2px;
            height: 10px;
            background-color: white;
            border-radius: 10px;
        }
        .stick-1 {
            transform: rotate(225deg);
        }
        .stick-2 {
            transform: rotate(135deg);
        }
    }

    .btn:hover {
        .arrow-container-down {
            visibility: hidden;
            opacity: 0;
            
            .stick-1, .stick-2 {
                background-color: $sign-up-blue;
            }
        }

        .arrow-container-up {
            visibility: visible;
            opacity: 1;
            
            .stick-1, .stick-2 {
                background-color: $sign-up-blue;
            }
        }
    }
      

    .navbar-content a {
        font-size: $medium;
        color: $white !important;

        @media (max-width: $desktop) {
            font-size: $small;
        }
    }

    .cart-area {
        padding: 0 0 0 20px;

        @media (min-width: $extrascreen) {
            padding: 0 12px;
        }

        @media (max-width: $tablet) {
            padding: 0;
        }

        .cart-icon {
            transition: filter 0.15s ease-in-out;
            vertical-align: middle;
        }

        .cart-count {
            background: rgba(253, 74, 74, 1);
            border-radius: 50%;
            color: $white;
            font-size: $tiny;
            height: 12px;
            line-height: 12px;
            padding-left: 1px;
            right: 2px;
            text-align: center;
            top: 2px;
            vertical-align: middle;
            width: 12px;
        }

        .right-link {
            margin-right: 17px !important;

            @media (max-width: $desktop) {
                margin-right: 13px !important;
            }
        }
    }
}
