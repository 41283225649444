@import 'styles/variables';

#manual-checkout-form {
    .file-name {
        align-items: flex-end;
        padding-bottom: 20px;
    }

    @media (max-width: $desktop) {
        #ds-file-input .form-file .form-file-icon {
            right: 110px;
        }
    }

    .arrow-icon {
        font-size: 9px;
        line-height: 2;
        
        @media (max-width: $desktop) {
            line-height: 1.6;
        }
    }
}
